import motivosCad from '@/data/motivosReporteCad.json';

export default {
  _id: {
    type: String,
    visibility: false
  },
  deviceId: {
    label: 'Device ID',
    type: String,
    visibility: false
  },
  reason: {
    label: 'Razón',
    type: String,
    options: motivosCad.map((m) => ({ id: m, name: m })),
  },
  description: {
    label: 'Descripción',
    type: String,
    textarea: true,
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false
  },
  city: {
    label: 'Municipio',
    type: String,
  },
  neighborhood: {
    label: 'Colonia',
    type: String,
  },
  address: {
    label: 'Dirección',
    type: String,
  }
};
