<template>
  <div class="murphy-div">
    <div class="d-flex px-4 pt-2">
      <v-text-field class="mt-2" label="Nombre(s)" v-model="nombre" dark :error="error ? true : false"
        :error-messages="error" :loading="loading" :disabled="loading" @keyup.enter="buscarPersona"></v-text-field>
      <v-text-field class="mt-2 ml-2" label="Primer Apellido" v-model="ap1" dark :error="error ? true : false"
        :loading="loading" :disabled="loading" @keyup.enter="buscarPersona">
      </v-text-field>
      <v-text-field class="mt-2 ml-2" label="Segundo Apellido" v-model="ap2" dark :error="error ? true : false"
        :loading="loading" :disabled="loading" @keyup.enter="buscarPersona">
      </v-text-field>
      <div class="pl-4" style="height: 80px; width: 80px">
        <button type="button" @click="buscarPersona">
          <img :src="murphyLogo" style="height: 80%; width: 95%; object-fit: contain" alt="Murphy" title="buscar" />
        </button>
      </div>
    </div>

    <v-carousel v-model="carousel" style="positon: relative" height="auto" :show-arrows="false" v-if="personas">
      <v-carousel-item v-for="(persona, i) in personas" :key="i" style="pading-right: 12px">
        <v-sheet height="100%" tile>
          <h4 class="text-center">DATOS GENERALES</h4>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mt-2 pb-2">
                <div class="data-title">Nombre</div>
                <div>{{ getNombre(persona) }}</div>
              </div>

              <div class="mt-2 pb-2 d-flex justify-md-space-between">
                <div>
                  <div class="data-title">Fecha de Nacimiento</div>
                  <div>{{ getFechaNacimiento(persona) }}</div>
                </div>
                <div>
                  <div class="data-title">Estado</div>
                  <div>{{ persona.estado || getEstadoIne(persona) || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <h4 class="text-center" v-if="persona.ine">INE</h4>
          <v-list-item three-line v-if="persona.ine">
            <v-list-item-content v-if="persona.ine">
              <div class="mt-2 pb-2 d-flex justify-md-space-between">
                <div>
                  <div cols="5" class="data-title">Estado</div>
                  <div>{{ persona.ine.estado || '------' }}</div>
                </div>
                <div>
                  <div class="data-title">Municipio</div>
                  <div>{{ persona.ine.municipio || '------' }}</div>
                </div>
                <div style="width: 50%">
                  <div class="data-title">Domicilio</div>
                  <div>{{ persona.ine.domicilio || '------' }}</div>
                </div>
              </div>
              <div class="mt-2 pb-2 d-flex justify-md-space-between">
                <div>
                  <div cols="5" class="data-title">Clave Elector</div>
                  <div>{{ persona.ine.clave || '------' }}</div>
                </div>
                <div>
                  <div class="data-title">Curp</div>
                  <div>{{ persona.ine.curp || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <h4 class="text-center" v-if="persona.lics && persona.lics.length > 0"> LICENCIAS </h4>
          <v-list-item three-line v-if="persona.lics && persona.lics.length > 0">
            <v-list-item-content>
              <div class="mt-2 pb-2 d-flex justify-md-space-between" v-for=" (lic, j) in persona.lics" :key="j">
                <div>
                  <div class="data-title">Estado</div>
                  <div>{{ lic.estado || '------' }}</div>
                </div>
                <div>
                  <div class="data-title">Vencimiento</div>
                  <div>{{ lic.vencimiento || '------' }}</div>
                </div>
                <div style="width: 50%">
                  <div class="data-title">Domicilio</div>
                  <div>{{ lic.domicilio || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <h4 class="text-center" v-if="persona.imss && persona.imss.length > 0"> IMSS </h4>
          <v-list-item three-line v-if="persona.imss && persona.imss.length > 0">
            <v-list-item-content>
              <div class="mt-2 pb-2 d-flex justify-md-space-between" v-for=" (imss, k) in persona.imss" :key="k">

                <div style="width: 40%;">
                  <div class="data-title">Patron</div>
                  <div>{{ imss.nom_patron || '------' }}</div>
                </div>
                <div style="width: 50%;">
                  <div class="data-title">Dirección</div>
                  <div>{{ imss.domicilio_patron + ', ' + imss.localidad_patron || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <h4 class="text-center" v-if="persona.faltas && persona.faltas.length > 0"> FALTAS ADMINISTRATIVAS </h4>
          <v-list-item three-line v-for=" (falta, l) in persona.faltas" :key="l">
            <v-list-item-content>
              <div class="mt-2 pb-2 d-flex justify-md-space-between">

                <div style="width: 33%;">
                  <div class="data-title">Fecha</div>
                  <div>{{ falta.fecha_hora_detencion || '------' }}</div>
                </div>
                <div style="width: 33%;">
                  <div class="data-title">Municipio</div>
                  <div>{{ falta.municipio || '------' }}</div>
                </div>
                <div style="width: 34%;">
                  <div class="data-title">Estado</div>
                  <div>{{ falta.estado || '------' }}</div>
                </div>
              </div>
              <div class="mt-2 pb-2 d-flex justify-md-space-between">
                <div style="width: 40%;">
                  <div class="data-title">Situación</div>
                  <div>{{ falta.situacion || '------' }}</div>
                </div>
                <div style="width: 60%;">
                  <div class="data-title">Descripción</div>
                  <div>{{ falta.motivo_catalogo || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <h4 class="text-center" style="color: red;" v-if="persona.mandamientos && persona.mandamientos.length > 0"> MANDAMIENTOS </h4>
          <v-list-item three-line v-if="persona.mandamientos && persona.mandamientos.length > 0">
            <v-list-item-content>
              <div class="mt-2 pb-2 d-flex justify-md-space-between" v-for=" (mandamiento, m) in persona.mandamientos"
                :key="m">
                <div>
                  <div class="data-title">Estado</div>
                  <div>{{ mandamiento.estado || '------' }}</div>
                </div>
                <div>
                  <div class="data-title">Expediente</div>
                  <div>{{ mandamiento.expediente || '------' }}</div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <h4 class="text-center" v-if="persona.foto">FOTOGRAFIA</h4>
          <v-layout justify-center v-if="persona.foto">
            <v-img :src="'data:image/jpeg;base64,' + persona.foto" max-width="35%" aspect-ratio="1.1"
              style="text-align: right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="18" dark class="cursor-pointer" v-on="on" color="black" @click="copyImage">
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </v-img>
          </v-layout>
          <br />
          <br />
          <br />
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import murphyLogo from '@/assets/murphy.png';
import { MurphyService } from '@/util/murphy.service';
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {},
  mounted() { },
  data() {
    return {
      carousel: 0,
      personas: '',
      loading: false,
      nombre: '',
      ap1: '',
      ap2: '',
      error: '',
      data: null,
      murphyLogo,
    };
  },
  methods: {
    getNombre(persona) {
      return [
        persona.nombre || '------',
        persona.apellido_1 || '------',
        persona.apellido_2 || '------',
      ]
        .join(' ')
        .trim();
    },
    getDomicilio(persona) {
      return persona?.Licencia?.Domicilio || persona?.Acta?.Domicilio || '------';
    },
    getFechaNacimiento(persona) {
      return persona.fecha_nac || '------';
    },
    getVigenciaLicencia(persona) {
      return persona?.Licencia?.FechaVigencia?.slice(0, 10) || '------';
    },
    getMandamientos(persona) {
      return persona?.Mandamientos?.length || '------';
    },
    getEstadoIne(persona) {
      return persona?.ine?.estado
    },
    buscarPersona() {
      const murphyUser = this.getUser?.attributes?.consulta_murphy;
      if (!murphyUser) {
        window.VMA.showError({ title: 'No tienes permisos para realizar esta consulta' })
        return;
      }
      this.loading = true;
      const murphyService = new MurphyService();
      murphyService.createQuery('Personas', murphyUser, { apellido_1: this.ap1, apellido_2: this.ap2, nombre: this.nombre }).then((value) => {
        const _personas = []
        if (Array.isArray(value?.queryObjects) && value.queryObjects.length) {
          value.queryObjects.forEach((persona) => {
            _personas.push(persona.properties);
          })
          this.personas = _personas;
          this.error = '';
          this.nombre = '';
          this.ap1 = '';
          this.ap2 = '';
        } else {
          this.error = 'Sin resultados';
        }
      }).catch((err) => {
        console.log('ERROR', err)
        window.VMA.showError({ title: 'Ocurrió un error en la consulta' })
      }).finally(() => {
        this.loading = false
      })
    },
    copy() {
      if (this.personas && this.personas.length > 0) {
        const persona = this.personas[this.carousel];
        const text = [
          'Nombre: ' + this.getNombre(persona),
          'Domicilio: ' + this.getDomicilio(persona),
          'Fecha de Nacimiento: ' + this.getFechaNacimiento(persona),
          'Vigencia de Licencia: ' + this.getVigenciaLicencia(persona),
          'Mandamientos: ' + this.getMandamientos(persona),
        ].join('\n');
        window.navigator.clipboard.writeText(text);
      }
    },
    async copyImage() {
      if (this.personas && this.personas.length > 0) {
        const persona = this.personas[this.carousel];
        // eslint-disable-next-line
        function convertBase64ToBlob(base64, type) {
          const bytes = window.atob(base64);
          const ab = new ArrayBuffer(bytes.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
          }
          return new Blob([ab], { type });
        }
        const blobInput = convertBase64ToBlob(persona.Licencia.imagen, 'image/png');
        // eslint-disable-next-line
        const clipboardItemInput = new ClipboardItem({ 'image/png': blobInput });
        await window.navigator.clipboard.write([clipboardItemInput]);
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
};
</script>

<style lang="scss" scoped>
.data-title {
  font-size: 0.6rem;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.murphy-div {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.v-list-item__content>*:not(:last-child) {
  border-bottom: 1px dashed lightslategrey;
}

.v-list-item__subtitle {
  user-select: text;
}

.v-list-item__title {
  user-select: text;
}

.v-carousel__item {
  overflow-y: auto;
}

.v-carousel__controls {
  height: 35px !important;
}
</style>
