import mapImages from '@/util/mapImages'
import Util from '@/util'
// import ApiService from '@/util/api.service'
import RapidSOS from '@/assets/map/rapidsos.png'
import BaseStore from './baseStore'

const ol = window.ol

class IncidentStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.visibleCondition = { status: ['ACTIVO'] }
    this.hoveredId = null
    this.sosIncidents = new Map()

    this.categoryCount = {
      seguridad: 0,
      medico: 0,
      proteccion_civil: 0,
      servicios_publicos: 0
    }
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      category: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN'
    }
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.longitude + Math.random() * 0.000001, item.latitude + Math.random() * 0.000001
      ])),
      name: item.id,
      population: 4000,
      rainfall: 500
    });
    item.typeOfItem = 'incidents'
    if (this.sosIncidents.has(item.uniqueId)) {
      item.typeOfItem = 'sosIncident'
      item.sosData = this.sosIncidents.get(item.uniqueId)
    }
    this.categoryCount[item.category]++
    marker.setId('incidents__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)
    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker.getGeometry().setCoordinates(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ]))
    }
    const visible = this._isVisible(item)
    Object.assign(record, item)
    this.notify(item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    const record = this.markers.get(item.id).get('record')
    item.typeOfItem = record.typeOfItem
    if (item.status === 'HISTORICO') {
      if (this.map.trip) {
        this.map.trip.emit('unsubscribecad', item.uniqueId)
      }
      this.categoryCount[item.category]--
      console.log('CLEAR TIEMOUT', record)
    }
    this._updateMarker(item)
    return null
  }

  _getStyle(item) {
    if (item.typeOfItem === 'sosIncident') {
      return this._getIcon(mapImages.incident.sos, item.name, {
        offsetY: -35,
        offsetX: 1
      })
    }
    if (item.selected) {
      return this._getStyleSelected(item)
    }
    const incidentIcon = this._getIcon(mapImages.incident[item.category] || mapImages.incident.seguridad, item.name, {
      scale: mapImages.incident.scale,
      offsetY: -35,
      offsetX: 1
    })
    if (item.rapidSosLocations && item.rapidSosLocations.length) {
      return [incidentIcon, this._getSosIcon({})]
    }
    return incidentIcon
  }

  _getStyleSelected(item) {
    const selectedIcon = this._getIcon(mapImages.incident[item.category + '_selected'] || mapImages.incident.seguridad, item.name, {
      scale: mapImages.incident.scale,
      offsetY: mapImages.incident.offsetY,
      offsetX: mapImages.incident.offsetX
    })
    if (item.rapidSosLocations && item.rapidSosLocations.length) {
      return [selectedIcon, this._getSosIcon({ anchor: [-5, -5], scale: 0.8 })]
    }
    return selectedIcon
  }

  _getSosIcon({ anchor = [6, 5], scale = 0.7 }) {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor,
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: RapidSOS,
        scale,
      }),
      zIndex: 20
    })
  }

  updateMarkersStyle() {
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      marker.setStyle(this._getStyle(marker.get('record')))
      this.hoveredId = null
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')))
      this.hoveredId = id
    }
  }
}
export default IncidentStore
